import { extendTheme, theme as base } from '@chakra-ui/react';

const theme = extendTheme({
 

  fonts: {
    heading: `who_asks_satan, ${base.fonts?.heading}`,
    body: `who_asks_satan, ${base.fonts?.body}`,
  },
});

export default theme;