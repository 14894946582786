import React from 'react';
import {
  Container,
  Box,
  Link,
  Image,
  Heading,
  VStack,
  Text,
  Tag,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';



const BlogTags = ({ tags }) => {
  return (
    <HStack spacing={2} marginTop={2}>
      {tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
}


const BlogAuthor = ({ date, name }) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${name}`}
      />
      <Text fontWeight="medium">{name}</Text>
      <Text>—</Text>
      <Text>{date.toLocaleDateString()}</Text>
    </HStack>
  );
}





function Blog1() {
    
  return (

    <><Container id='blogsection' maxW={'7xl'} p="12">
        <Heading as="h1">What is the right type of roof for you?</Heading>
        <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '3', sm: '0' }}>
            <BlogTags tags={['Roofing', 'Comparison']} />
            <Text
              as="p"
              marginTop="2"
              color={useColorModeValue('gray.700', 'gray.200')}
              fontSize="lg">
             There are many different types of roofs to choose from, each with their own unique benefits and drawbacks. In this blog post, we'll compare some of the most popular types of roofs and discuss which one might be the best fit for your needs.
            </Text>
            <BlogAuthor name="Tracy Basset" date={new Date('2022-10-06T19:01:27Z')} />
          </Box>
        <Box
          marginTop={{ base: '1', sm: '5' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="space-between">
          <Box
            display="flex"
            flex="1"
            marginRight="0"
            position="relative"
            alignItems="center"
            alignContent="center"
              
              justifyContent="center">
            <Box
              width={{ base: '100%', sm: '85%' }}
              zIndex="2"
              marginLeft={{ base: '0', sm: '5%' }}
              marginTop="5%"
              >
             
                <Image
                  borderRadius="lg"
                  height='md'
                  src={'https://ucarecdn.com/ee67b22e-75bb-43a3-8402-a04c483b3a0b/639ff02a6949fb2a0cc6fc20_roofingmaterialse1594087310467.jpeg'}
                  alt="5 types of roof"
                  objectFit="contain" />
              
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box
                bgGradient={useColorModeValue(
                  'radial(orange.600 1px, transparent 1px)',
                  'radial(orange.300 1px, transparent 1px)'
                )}
                backgroundSize="20px 20px"
                opacity="0.4"
                height="100%" />
            </Box>
          </Box>
          
        </Box>
        
        <VStack paddingTop="40px" spacing="6" alignItems="flex-start">
          <Heading as="h2">A Comparison and Guide to Finding the Best Fit for Your Home</Heading>
          <Text as="p" fontSize="lg">
          Roofing is a crucial aspect of any home or building, as it provides protection from the elements and can significantly impact the energy efficiency and overall aesthetic of the structure. There are many different types of roofs to choose from, each with their own unique benefits and drawbacks. In this blog post, we'll compare some of the most popular types of roofs and discuss which one might be the best fit for your needs.
          </Text>
          <Heading as="h4">Asphalt Shingle Roof</Heading>
          <Image
                  borderRadius="lg"
                  height='md'
                  src={'https://ucarecdn.com/a13c58b0-2577-4fda-b848-28d026e37805/asphalt_shingle.webp'}
                  alt="5 types of roof"
                  objectFit="contain"
                  alignSelf="center" />
          <Text as="p" fontSize="lg" marginBottom={2}>
          One of the most traditional and popular types of roofs is the asphalt shingle roof. Asphalt shingles are made from a fiberglass or organic mat that is coated with asphalt and topped with mineral granules. These roofs are relatively inexpensive and easy to install, making them a popular choice for homeowners. Asphalt shingles are also relatively durable and can last for up to 20 years with proper maintenance. However, they may not be as durable as other types of roofs in extreme weather conditions and may require more frequent repairs or replacements.
          </Text>
          <Heading as="h4">Metal Roof</Heading>
          <Image
                  borderRadius="lg"
                  height='md'
                  src={'https://ucarecdn.com/24f51782-71b5-4f8c-858f-3824c7cf990c/metal_roof.jpeg'}
                  alt="5 types of roof"
                  objectFit="contain"
                  alignSelf="center" />
          <Text as="p" fontSize="lg">
          Another popular type of roof is the metal roof. Metal roofs are made from materials such as steel, aluminum, or copper and are known for their durability and long lifespan. Metal roofs can last for 50 years or more with proper maintenance, making them a great long-term investment. They are also energy-efficient, as they reflect heat and can help to reduce cooling costs in the summer. However, metal roofs can be more expensive to install and may not be suitable for all home styles.
          </Text>
          <Heading as="h4">Tile Roof</Heading>
          <Image
                  borderRadius="lg"
                  height='md'
                  src={'https://ucarecdn.com/b0ece5f7-3ec1-48fb-af19-ef57c096f7a7/tile_roof.webp'}
                  alt="5 types of roof"
                  objectFit="contain"
                  alignSelf="center" />
          <Text as="p" fontSize="lg">
          Tile roofs are another popular option, particularly in areas with a Mediterranean or Spanish-influenced architecture. Tile roofs are made from clay or concrete and are known for their durability and longevity. Tile roofs can last for 100 years or more and are resistant to extreme weather conditions, making them a great choice for those looking for a long-term roofing solution. However, tile roofs can be heavy and may require additional structural support, and they can be more expensive to install than other types of roofs.
          </Text>
          <Heading as="h4">Slate Roofs</Heading>
          <Image
                  borderRadius="lg"
                  height='md'
                  src={'https://ucarecdn.com/0ee1303a-d109-40ae-b9b4-2c499e3fc255/slate_roof.jpeg'}
                  alt="5 types of roof"
                  objectFit="contain"
                  alignSelf="center" />
          <Text as="p" fontSize="lg">
          Slate roofs are another high-end option that is known for its durability and longevity. Slate roofs can last for 100 years or more and are resistant to extreme weather conditions. They are also low maintenance and energy-efficient, as they can help to regulate the temperature inside the home. However, slate roofs are very expensive to install and may not be suitable for all budgets.
          </Text>
          <Text as="p" fontSize="lg">
          So, which type of roof is the best fit for your needs? It really depends on your budget, the style of your home, and your long-term goals. If you're looking for an affordable and easy-to-install option, asphalt shingles may be the way to go. If you're willing to invest in a more durable and long-lasting solution, a metal or tile roof might be a better fit. And if you have a larger budget and are looking for the ultimate in durability and longevity, a slate roof might be the right choice. If you're looking for Roofing Professionals for your home, check out <Link href="https://www.miahomeimprovement.com" isExternal color="red">MIA Home Improvement</Link> and find the best Pros in Miami, FL.

As for what's trending for 2023, it's tough to say for certain. However, with the increasing focus on sustainability and energy efficiency, metal and tile roofs may continue to gain popularity. As always, it's important to consider your specific needs and budget when selecting a roofing material.
          </Text>
        </VStack>
      </Container></>
  );
}

export default Blog1;
