import React from 'react';
import Home from './components/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
//import Header from './components/Header';
import  Navbar  from './components/Navbar';
import { ChakraProvider } from '@chakra-ui/provider';
import Blog1 from './components/Blog1';
import Blog2 from './components/Blog2';
import Footer from './components/Footer';
import NavProvider from './context/NavContext';


export const myThemeProvider = ({ children }) => {
  return <ChakraProvider>{children}</ChakraProvider>;
};

function App() {
  return (
    
      <Router>
        <NavProvider>
          <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/type-of-roof' exact component={Blog1} />
          <Route path='/why-gutters' exact component={Blog2} />
        </Switch>
        <Footer />
        </NavProvider>
      </Router>
    
  );
}

export default App;
