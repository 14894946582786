import {
  AspectRatio,
  Box,
  chakra,
  Container,
  Link,
  Image,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
//import { FaInstagram, FaTwitter } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';




const SocialButton = ({
  children,
  label,
  href,
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function SmallCentered() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        spacing={4}
        justify={'center'}
        align={'center'}>
        
        <Image src="https://ucarecdn.com/caa441b2-bb55-408d-9065-da961093e9b4/logo192.png" alt='mia-digital-logo' objectFit='cover' />
        
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <Text>© 2023 MIA DIGITAL SERVICES. All rights reserved</Text>
          <Stack direction={'row'} spacing={6}>
            {/*<SocialButton label={'Twitter'} href={'#'}>
              <FaTwitter />
            </SocialButton>*/}
            <chakra.button
          bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
          rounded={'full'}
          w={8}
          h={8}
          cursor={'pointer'}
          as={'a'}
          href="mailto: hello@miadigitalservices.com"
          display={'inline-flex'}
          alignItems={'center'}
          justifyContent={'center'}
          transition={'background 0.3s ease'}
          _hover={{
              bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
          }}>
          <VisuallyHidden>Email</VisuallyHidden>
          <MdEmail />
      </chakra.button>
            {/*<SocialButton label={'Instagram'} href={'#'}>
              <FaInstagram />
        </SocialButton>*/}
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}