import React from 'react';
import {
  Container,
  Box,
  Button,
  Link,
  Wrap,
  WrapItem,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Divider,
  VStack,
  Text,
  Tag,
  HStack,
  Stack,
  StackDivider,
  Icon,
  IconButton,
  createIcon,
  useColorModeValue,
} from '@chakra-ui/react';

import {BsGraphUp} from 'react-icons/bs';
import { FcAssistant, FcDeployment, FcInTransit } from 'react-icons/fc';
import WithSpeechBubbles from './Testimonials';
import ContactFormWithSocialButtons from './ContactUs';
import Pricing from './Pricing';
import BlogSection from './BlogSection';
import { useNav } from '../customHooks/useNav';






const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};


export const Blob = (props) => {
  return (React.createElement(Icon, Object.assign({ width: '100%', viewBox: "0 0 578 440", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
      React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z", fill: "currentColor" })));
};


async function scrollToPricing() {
  document.getElementById('pricing').scrollIntoView({behavior: 'smooth', block: 'center'})
}

async function scrollToContact() {
  document.getElementById('contact').scrollIntoView({behavior: 'smooth', block: 'center'})
}






const Description = ({ title, text, icon }) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};


function Home() {

  const homeRef = useNav('Home');
  const aboutRef = useNav('About');

    
  return (

    <><><Container maxW={'7xl'} marginBottom="80px" ref={homeRef} id='homeContainer'>
    <Stack
      align={'center'}
      spacing={{ base: 8, md: 10 }}
      py={{ base: 20, md: 28 }}
      direction={{ base: 'column', md: 'row' }}>
      <Stack flex={1} spacing={{ base: 5, md: 10 }}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
          <Text
            as={'span'}
            position={'relative'}
            _after={{
              content: "''",
              width: 'full',
              height: '30%',
              position: 'absolute',
              bottom: 1,
              left: 0,
              bg: 'red.400',
              zIndex: -1,
            }}>
            Digital Marketing
          </Text>
          <br />
          <Text as={'span'} color={'red.400'}>
            done right!
          </Text>
        </Heading>
        <Text color={'gray.500'}>
          At MIA Digital Services we handle the websites, marketing and other digital services so you can focus on your business. Get more leads and customers through our innovative digital marketing strategy!
        </Text>
        <Stack
          spacing={{ base: 4, sm: 6 }}
          direction={{ base: 'column', sm: 'row' }}>
          <Button
            onClick={scrollToContact}
            rounded={'full'}
            size={'lg'}
            fontWeight={'normal'}
            px={6}
            colorScheme={'red'}
            bg={'red.400'}
            _hover={{ bg: 'red.500' }}>
            Get started
          </Button>
          <Button
            rounded={'full'}
            size={'lg'}
            fontWeight={'normal'}
            px={6}
            onClick={scrollToPricing}>
            Pricing & Services
          </Button>
        </Stack>
      </Stack>
      <Flex
        flex={1}
        justify={'center'}
        align={'center'}
        position={'relative'}
        w={'full'}>
        
        
        <Blob
          w={'150%'}
          h={'150%'}
          position={'absolute'}
          top={'-20%'}
          left={0}
          zIndex={-1}
          color={useColorModeValue('red.50', 'red.400')}
        />
        
        
        <Box
          position={'relative'}
          height={'300px'}
          rounded={'2xl'}
          boxShadow={'2xl'}
          width={'full'}
          overflow={'hidden'}>
    
          <Image
            alt={'Hero Image'}
            fit={'cover'}
            align={'center'}
            w={'100%'}
            h={'100%'}
            src={
              'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80'
            }
          />
        </Box>
      </Flex>
    </Stack>
  </Container>
    
    <Divider marginBottom="40px" />

    <Box p={4} marginLeft="40px" marginRight="40px" marginBottom="40px">
    <Box width="15%" alignContent="center" alignItems="center" textAlign="center">
    <Text
              textTransform={'uppercase'}
              color={'red.400'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('red.50', 'red.900')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}
              marginBottom="30px">
              Our Proven Process
            </Text>
            </Box>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Description
          icon={<Icon as={FcAssistant} w={10} h={10} />}
          title={"STEP 1: Let's Talk"}
          text={
            'Time for us to connect! We ask questions about you, your business, your style, preferences, requirements and goals.'
          }
        />
        <Description
          icon={<Icon as={FcDeployment} w={10} h={10} />}
          title={'STEP 2: Building Begins'}
          text={
            'We build you a full digital marketing strategy. This can include a new website with built-in SEO, digital ads through marketing channels such as Google, Facebook, Yelp, and much more!'
          }
        />
        <Description
          icon={<Icon as={FcInTransit} w={10} h={10} />}
          title={'STEP 3: Launch 🚀'}
          text={
            "We'll get everything ready for you while you get to focus on your business. We'll give you monthly update reports, and provide you with lifetime customer support."
          }
        />
      </SimpleGrid>
    </Box>

    <Divider marginBottom="80px" />

         
    <Container maxW={'5xl'} py={12} ref={aboutRef} id='aboutContainer'>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Text
              textTransform={'uppercase'}
              color={'red.400'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('red.50', 'red.900')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}>
              Our Story
            </Text>
            <Heading>MIA Digital Services - The Leading Digital Services Provider in over 4 years!</Heading>
            <Text color={'gray.500'} fontSize={'lg'}>
              We're a BIG fan of the basics and customer usability. Our digital marketing strategy focuses on straightforward, simple, personal and approachable marketing materials and websites. We choose this approach over a creative overload of color, motion and style. And let me tell you something: IT WORKS!
            </Text>
            <Stack
              spacing={4}
              divider={<StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')} />}>
              <Feature
                icon={<Icon as={BsGraphUp} color={'yellow.500'} w={5} h={5} />}
                iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                text={'Websites that are ready to convert leads into sales!'} />
              <Feature
                icon={<Icon as={BsGraphUp} color={'green.500'} w={5} h={5} />}
                iconBg={useColorModeValue('green.100', 'green.900')}
                text={'Digital Marketing & Ads'} />
              <Feature
                icon={<Icon as={BsGraphUp} color={'purple.500'} w={5} h={5} />}
                iconBg={useColorModeValue('purple.100', 'purple.900')}
                text={'SEO & Competitor Research and Analysis'} />
            </Stack>
          </Stack>
          <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={'https://ucarecdn.com/0b1ba305-6387-4cc1-8df3-292e04b38fca/stephendawsonqwtCeJ5cLYsunsplash.jpg'}
              objectFit={'cover'} />
          </Flex>
        </SimpleGrid>
      </Container>
       </>
      
      <WithSpeechBubbles />


               <Container id='pricing'>
                <Pricing />
                </Container>

                <Box id='contact' width="100vw">
      <ContactFormWithSocialButtons />
                </Box>

                
      
      </>
  );
}

export default Home;
