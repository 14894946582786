import React from 'react';
import {
  Container,
  Box,
  Link,
  Image,
  Heading,
  VStack,
  Text,
  Tag,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';



const BlogTags = ({ tags }) => {
  return (
    <HStack spacing={2} marginTop={2}>
      {tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
}


const BlogAuthor = ({ date, name }) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${name}`}
      />
      <Text fontWeight="medium">{name}</Text>
      <Text>—</Text>
      <Text>{date.toLocaleDateString()}</Text>
    </HStack>
  );
}





function Blog2() {
    
  return (

    <><Container id='blogsection' maxW={'7xl'} p="12">
        <Heading as="h1">The Top Reasons you need Gutters in Miami</Heading>
        <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '3', sm: '0' }}>
            <BlogTags tags={['Roofing', 'Comparison']} />
            <Text
              as="p"
              marginTop="2"
              color={useColorModeValue('gray.700', 'gray.200')}
              fontSize="lg">
             There are many different types of gutters to choose from, in this blog post we'll cover the top reasons as to why you should have gutters if you live in South Florida.
            </Text>
            <BlogAuthor name="Tracy Basset" date={new Date('2022-10-06T19:01:27Z')} />
          </Box>
        <Box
          marginTop={{ base: '1', sm: '5' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="space-between">
          <Box
            display="flex"
            flex="1"
            marginRight="0"
            position="relative"
            alignItems="center"
            alignContent="center"
              
              justifyContent="center">
            <Box
              width={{ base: '100%', sm: '85%' }}
              zIndex="2"
              marginLeft={{ base: '0', sm: '5%' }}
              marginTop="5%"
              >
             
                <Image
                  borderRadius="lg"
                  height='md'
                  src={'https://ucarecdn.com/d82ec771-4494-4956-8c36-96c8e219d25a/gutters_blog.png'}
                  alt="white gutter in a house"
                  objectFit="contain" />
              
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box
                bgGradient={useColorModeValue(
                  'radial(orange.600 1px, transparent 1px)',
                  'radial(orange.300 1px, transparent 1px)'
                )}
                backgroundSize="20px 20px"
                opacity="0.4"
                height="100%" />
            </Box>
          </Box>
          
        </Box>
        
        <VStack paddingTop="40px" spacing="6" alignItems="flex-start">
          <Heading as="h2">The Top Benefits of Gutters</Heading>
          <Text as="p" fontSize="lg">
          Gutters are an important part of any home's exterior. They help to collect and redirect rainwater away from the foundation of the house, preventing damage to the structure and the surrounding landscaping. In this post, we'll discuss the benefits of gutters and why every home needs them.
          </Text>
          <Image
                  borderRadius="lg"
                  height='md'
                  src={'https://ucarecdn.com/cb9a4398-56a3-42f5-a31f-93c314244eca/gutter_matching.jpeg'}
                  alt="5 types of roof"
                  objectFit="contain"
                  alignSelf="center" />
          
          <Heading as="h4">Preventing Water Damage</Heading>
         
          <Text as="p" fontSize="lg">
          One of the main benefits of gutters is that they help to prevent water damage to your home. When it rains, water can collect on the roof of your home and run down the sides, potentially damaging the exterior walls, foundation, and landscaping. Gutters collect this water and redirect it away from your home, preventing it from pooling near the foundation and causing damage.
          </Text>
          <Heading as="h4">Preventing Erosion</Heading>
          <Text as="p" fontSize="lg">
          Another benefit of gutters is that they protect your home from erosion. When water collects near the foundation of your home, it can cause the soil to erode and wash away. Over time, this can lead to instability and even damage to the foundation of your home. Gutters help to prevent erosion by redirecting water away from the foundation, protecting the soil and ensuring that your home remains stable.
          </Text>
          <Heading as="h4">Landscaping and Appearance</Heading>
        
          <Text as="p" fontSize="lg">
          Gutters also help to protect your landscaping. When water collects near the foundation of your home, it can damage plants and grass, leading to unsightly dead spots and bare patches. Gutters help to prevent this by redirecting water away from your landscaping, protecting your plants and keeping your yard looking beautiful.
          </Text>
          <Text as="p" fontSize="lg">
          In addition to these benefits, gutters can also improve the overall appearance of your home. When water collects on the roof and runs down the sides of your home, it can leave unsightly streaks and stains. Gutters help to prevent this by redirecting water away from the exterior of your home, keeping it clean and looking like new.

‍

Overall, gutters are an important part of any home's exterior. They help to prevent water damage, protect against erosion, and improve the appearance of your home. If you don't have gutters, or if your existing gutters are old and in need of repair, it's important to have them installed or replaced as soon as possible to ensure that your home is protected.
          
           If you're looking for Roofing or Gutter Professionals for your home, check out <Link href="https://www.miahomeimprovement.com" isExternal color="red">MIA Home Improvement</Link> and find the best Pros in Miami, FL.


          </Text>
        </VStack>
      </Container></>
  );
}

export default Blog2;
