import React, { useContext } from 'react';
import { NavContext } from '../context/NavContext';
import {
    Button,
    useColorModeValue,
    
  } from '@chakra-ui/react'

const NavLink = ({ navLinkId, scrollToId }) => {
	const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext);

    const linkColor = useColorModeValue('black','white');

	const handleClick = () => {
		setActiveNavLinkId(navLinkId);
		document.getElementById(scrollToId).scrollIntoView({ behavior: 'smooth' });
	};

	return (
        <span id={navLinkId}
        >
        <Button
          variant="link"
          color={activeNavLinkId === navLinkId ? "red.600" : linkColor }
          _hover={{
            color: {
              base: "red.600",
              sm: "red.100",
              md: "red.600",
              lg: "red.600"
            },
            textDecoration: "none"
          }}
          _active={{
            color: {
              base: "red.800",
              sm: "red.100",
              md: "red.800",
              lg: "red.800"
            }
          }}
          display="inline-flex"
          mr="20px"
          mb={{ base: 0, sm: "1rem", md: 0, lg: 0 }}
         
          
          onClick={handleClick}>
          {navLinkId}
        </Button>
      </span>
	);
};



export default NavLink;