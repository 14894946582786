import React, { useRef, useCallback } from 'react';
import {
  Box,
  Flex,
  Button,
  IconButton,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react'
import {ColorModeSwitcher} from '../ColorModeSwitcher'
import useOnClickOutside from "../hooks/useOnClickOutside";
import NavLink from './NavLink';
import { navLinks } from './navLinks';









// header menu icon
const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="black"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

// header menu close icon
const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="black"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

//
// Responsive header component
//
const Navbar = () => {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  const headerRef = useRef(null);

  useOnClickOutside(headerRef, () => {
    setShow(false);
  });

  const menuItemCloseNav = useCallback(() => {
    setShow(!show);
  }, [show]);
  const bg = useColorModeValue('white','gray.800');
  const iconbg = useColorModeValue('gray.800','white');
 
 
  return (
    <Flex
      as="header"
      position="sticky"
      //direction="column"
      top="0"
      w="100vw"
      p={0}
      color={["white", "white", "white", "primary.700"]}
      bg={bg}
      boxShadow={useColorModeValue(
        "0px 0.25rem 0.75rem 0px rgb(190 194 255 / 18%)",
        "0px 0.25rem 0.75rem 0px rgb(0 14 81 / 35%)"
      )}
      zIndex={999}
      ref={headerRef}
      
    >
      <Flex
        justify="space-between"
        align="center"
        wrap="wrap"
        w="100%"
        maxW="1366px"
        m="auto"
        px="20px"
        py={6}
      >
        <Flex align="center">
        <a href="/">
            <img alt='mini logo' src="./logo72.png"/>
          </a>
          
        </Flex>

        <Box display={{ base: "block", md: "none" }} onClick={toggleMenu}>
          {show ? (
            <IconButton variant="ghost">
              <CloseIcon />
            </IconButton>
          ) : (
            <MenuIcon />
          )}
        </Box>

        <Box
          display={{ base: show ? "flex" : "none", md: "flex" }}
          justifyContent="center"
          align="center"
          flexBasis={{ base: "100%", md: "auto" }}
        >
          <Flex
            justify="center"
            align="center"
            direction={["column", "column", "column", "row"]}
            
          >
            {navLinks.map(({ navLinkId, scrollToId }, idx) => (
				<NavLink key={idx} navLinkId={navLinkId} scrollToId={scrollToId} />
			))}
          </Flex>
          <ColorModeSwitcher color={iconbg} justifySelf="flex-end" />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Navbar;