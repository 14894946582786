// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASEAPI,
  authDomain: process.env.REACT_APP_FIREBASEAUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASEPROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASESTORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASEMESSAGINGID,
  appId: process.env.REACT_APP_FIREBASEAPPID
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();

export { db };