import React from 'react';
import {
  Container,
  Box,
  Button,
  Link,
  Wrap,
  WrapItem,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Divider,
  VStack,
  Text,
  Tag,
  HStack,
  Stack,
  StackDivider,
  Icon,
  IconButton,
  createIcon,
  useColorModeValue,
} from '@chakra-ui/react';



  const BlogAuthor = ({ date, name }) => {
    return (
      <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
        <Image
          borderRadius="full"
          boxSize="40px"
          src="https://100k-faces.glitch.me/random-image"
          alt={`Avatar of ${name}`}
        />
        <Text fontWeight="medium">{name}</Text>
        <Text>—</Text>
        <Text>{date.toLocaleDateString()}</Text>
      </HStack>
    );
  }

  const BlogTags = ({ tags }) => {
    return (
      <HStack spacing={2} marginTop={2}>
        {tags.map((tag) => {
          return (
            <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
              {tag}
            </Tag>
          );
        })}
      </HStack>
    );
  }


function BlogSection() {

    return(
        <Container id='blogsection' maxW={'7xl'} p="12">
        <Heading as="h2" marginTop="5">
          Latest articles
        </Heading>
        <Divider marginTop="5" />
        <Wrap spacing="30px" marginTop="5">
          <WrapItem width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
            <Box w="100%">
              <Box borderRadius="lg" overflow="hidden">
                <Link href="/type-of-roof" textDecoration="none" _hover={{ textDecoration: 'none' }}>
                  <Image
                    transform="scale(1.0)"
                    src={'https://ucarecdn.com/ee67b22e-75bb-43a3-8402-a04c483b3a0b/639ff02a6949fb2a0cc6fc20_roofingmaterialse1594087310467.jpeg'}
                    alt="4 types of roof"
                    objectFit="contain"
                    width="100%"
                    transition="0.3s ease-in-out"
                    _hover={{
                      transform: 'scale(1.05)',
                    }} />
                </Link>
              </Box>
              <BlogTags tags={['Roofing', 'Comparison']} marginTop="3" />
              <Heading fontSize="xl" marginTop="2">
                <Link href="/type-of-roof" textDecoration="none" _hover={{ textDecoration: 'none' }}>
                What is the right Type of Roof For You?
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
              There are many different types of roofs to choose from, each with their own unique benefits and drawbacks. In this blog post, we'll compare some of the most popular types of roofs and discuss which one might be the best fit for your needs.
              </Text>
              <BlogAuthor
                name="Tracy Basset"
                date={new Date('2022-10-08T19:01:27Z')} />
            </Box>
          </WrapItem>
          <WrapItem width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
            <Box w="100%">
              <Box borderRadius="lg" overflow="hidden">
                <Link href="/why-gutters" textDecoration="none" _hover={{ textDecoration: 'none' }}>
                  <Image
                    transform="scale(1.0)"
                    src={'https://ucarecdn.com/d82ec771-4494-4956-8c36-96c8e219d25a/gutters_blog.png'}
                    alt="5 types of roof"
                    objectFit="contain"
                  
                    width="100%"
                    transition="0.3s ease-in-out"
                    _hover={{
                      transform: 'scale(1.05)',
                    }} />
                </Link>
              </Box>
              <BlogTags tags={['Gutters', 'Blog']} marginTop="3" />
              <Heading fontSize="xl" marginTop="2">
                <Link href="/why-gutters" textDecoration="none" _hover={{ textDecoration: 'none' }}>
                The Top Reasons you need Gutters in Miami
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
              There are many different types of gutters to choose from, in this blog post we'll cover the top reasons as to why you should have gutters if you live in South Florida.
              </Text>
              <BlogAuthor
                name="Tracy Basset"
                date={new Date('2022-11-06T19:01:27Z')} />
            </Box>
          </WrapItem>
        </Wrap>
        
      </Container>
    );
}

export default BlogSection;